<!--
 * @Author: your name
 * @Date: 2021-07-30 11:32:27
 * @LastEditTime: 2021-10-14 15:16:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\activity\index.vue
-->
<template>
  <div class="activity">
    <div class="container">
      <div class="search flex-h flex-vc">
        <h4>找活动</h4>
        <div class="searchBox flex-1">
          <input type="text" v-model="fieldKey" />
          <div
            class="searchBtn flex-h flex-hc flex-vc"
            @click="
              dateValue = [];
              activityType = '';
              activityStatus = null;
              currPage = 1;
              getList();
            "
          >
            <img src="../../assets/img/search.png" />
            <span>搜索</span>
          </div>
        </div>
      </div>
      <div class="status">
        <div class="formItem flex-h">
          <div class="label">服务类型</div>
          <div class="tabs">
            <span
              class="tab"
              @click="changeType(index, item.id)"
              :class="{ active: currentIndex == index }"
              v-for="(item, index) in tabs"
              :key="index"
              >{{ item.typeName }}</span
            >
          </div>
        </div>
        <div class="formItem flex-h">
          <div class="label">活动状态</div>
          <div class="tabs">
            <span
              class="tab"
              :class="{ active: activityStatus == null }"
              @click="changeStatus(null)"
              >全部</span
            >
            <span
              class="tab"
              :class="{ active: activityStatus == 0 }"
              @click="changeStatus(0)"
              >即将开始</span
            >
            <span
              class="tab"
              :class="{ active: activityStatus == 1 }"
              @click="changeStatus(1)"
              >正在进行</span
            >
            <span
              class="tab"
              :class="{ active: activityStatus == 2 }"
              @click="changeStatus(2)"
              >已结束</span
            >
          </div>
        </div>
        <div class="formItem flex-h flex-vc">
          <div class="label">活动日期</div>
          <el-date-picker
            class="formDataPicker"
            v-model="dateValue"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
          >
          </el-date-picker>
          <div class="button" @click="getList()">搜索</div>
        </div>
      </div>
      <div class="activityList flex-h">
        <dl v-for="(item, index) in list" :key="index">
          <dt>
            <img :src="item.activityLogo" />
            <div class="tag" v-if="item.status == 0">即将开始</div>
            <div class="tag" v-if="item.status == 1">正在进行</div>
            <div class="tag" v-if="item.status == 2">已结束</div>
          </dt>
          <dd>
            <h1>{{ item.activityName }}</h1>
            <p>
              活动时间：{{ $formatDate(item.startTime, "{y}/{m}/{d}") }} -
              {{ $formatDate(item.endTime, "{y}/{m}/{d}") }}
            </p>
            <p>活动地点：{{ item.activityAddr.length>17?item.activityAddr.substr(0,16)+"...":item.activityAddr }}</p>
            <p>计划招募：{{ item.activityMember }}人</p>
            <div
              class="button"
              @click="
                $router.push({
                  name: 'activityDetail',
                  query: { id: item.activityId },
                })
              "
            >
              查看详情
            </div>
          </dd>
        </dl>
      </div>
      <div class="Pagination">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currPage"
          :page-size="limit"
          layout=" prev, pager,  next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { activityList, activityTypeList } from "@/api/index";
export default {
  data() {
    return {
      tabs: [],
      dateValue: [],
      activityType: "",
      fieldKey: "",
      activityStatus: null,
      currentIndex: 0,
      limit: 9,
      total: 0,
      currPage: 1,
      list: [],
    };
  },
  methods: {
    getList() {
      activityList({
        addrId: this.location.addrId,
        currPage: this.currPage,
        activityStatus: this.activityStatus,
        pageLimit: this.limit,
        activityType: this.activityType,
        fieldKey: this.fieldKey,

        startTime: this.dateValue ? this.dateValue[0] / 1000 : "",
        endTime: this.dateValue ? this.dateValue[1] / 1000 : "",
      }).then((res) => {
          this.list = res.data.records;
          this.total = res.data.total;
      });
    },
    changeStatus(status) {
      this.activityStatus = status;
      this.currPage = 1;
      this.getList();
    },
    changeType(index, id) {
      this.currentIndex = index;
      this.activityType = id;
      this.currPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currPage = val;
      this.getList();
    },
    getTypeList() {
      activityTypeList().then((res) => {
        this.tabs = [{ id: "", typeName: "全部" }, ...res.data.list];
        this.getList();
      });
    },
  },
  created() {
    this.getTypeList();
  },
  watch:{
    location(newV,oldV){
     this.getList()
    }
  },
  computed: {
    location() {
      return this.$store.state.location;
    },
  },
};
</script>
<style lang="scss" scoped>
    .activity {
        background: #f6f6f6;
        padding: 20px 0;

        .search {
            h4 {
                font-size: 22px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #fb3947;
            }

            .searchBox {
                margin-left: 14px;
                height: 47px;
                background: #ffffff;
                border: 1px solid #ff6e6e;

                input {
                    width: calc(100% - 93px);
                    line-height: 47px;
                    font-size: 20px;
                    text-indent: 20px;
                }

                .searchBtn {
                    float: right;
                    width: 93px;
                    height: 100%;
                    background: #ff6e6e;
                    cursor: pointer;

                    img {
                        width: 21px;
                    }

                    span {
                        margin-left: 2px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 47px;
                    }
                }
            }
        }

        .status {
            padding: 20px;
            background: #ffffff;
            border-radius: 13px;
            margin-top: 23px;
            margin-bottom: 30px;

            .formItem {
                margin-bottom: 10px;

                .label {
                    font-size: 15px;
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                    font-weight: bold;
                    color: #737373;
                    white-space: nowrap;
                    height: 29px;
                    line-height: 29px;
                }

                .tabs {
                    margin-left: 28px;

                    .tab {
                        display: inline-block;
                        height: 29px;
                        border-radius: 15px;
                        cursor: pointer;
                        font-size: 15px;
                        font-family: MicrosoftYaHei;
                        color: #8f8f8f;
                        line-height: 29px;
                        padding: 0 20px;
                        margin-bottom: 10px;

                        &.active {
                            background: #ff6e6e;
                            color: #fff;
                        }
                    }
                }

                .formDataPicker {
                    margin-left: 40px;
                    margin-right: 25px;
                }

                .button {
                    width: 80px;
                    height: 32px;
                    background: #ff6e6e;
                    border-radius: 16px;
                    text-align: center;
                    line-height: 32px;
                    cursor: pointer;
                    font-size: 18px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                }
            }
        }

        .activityList {
            flex-wrap: wrap;

            dl {
                width: 377px;
                height: 490px;
                margin-right: 23px;
                margin-bottom: 33px;
                background: #f8f8f8;
                box-shadow: 0px 6px 4px 0px #d8d8d8;
                border-radius: 0px 0px 10px 10px;

                dt {
                    position: relative;
                    height: 226px;

                    img {
                        width: 100%;
                        height: 100%;
                    }

                    .tag {
                        width: 96px;
                        height: 28px;
                        background: #fb3947;
                        border-radius: 14px;
                        text-align: center;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 28px;
                        position: absolute;
                        top: 13px;
                        right: 14px;
                    }
                }

                dd {
                    h1 {
                        height: 28px;
                        font-size: 20px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #1a1a1a;
                        line-height: 28px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        margin: 0 auto;
                        margin-top: 22px;
                        margin-bottom: 17px;
                        margin-left: 15px;
                    }

                    p {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #1a1a1a;
                        margin-bottom: 20px;
                        margin-left: 15px;
                    }

                    .button {
                        width: 163px;
                        height: 44px;
                        background: #fb3947;
                        border-radius: 22px;
                        font-size: 18px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 44px;
                        text-align: center;
                        cursor: pointer;
                        margin: 0 auto;
                    }
                }
            }
        }

        .Pagination {
            text-align:center;
        }
    }
</style>